// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-analysis-js": () => import("./../../../src/pages/analysis.js" /* webpackChunkName: "component---src-pages-analysis-js" */),
  "component---src-pages-betting-card-js": () => import("./../../../src/pages/betting_card.js" /* webpackChunkName: "component---src-pages-betting-card-js" */),
  "component---src-pages-games-js": () => import("./../../../src/pages/games.js" /* webpackChunkName: "component---src-pages-games-js" */),
  "component---src-pages-games-nfl-confidence-picks-js": () => import("./../../../src/pages/games/nfl-confidence-picks.js" /* webpackChunkName: "component---src-pages-games-nfl-confidence-picks-js" */),
  "component---src-pages-games-nfl-ev-bets-js": () => import("./../../../src/pages/games/nfl-ev-bets.js" /* webpackChunkName: "component---src-pages-games-nfl-ev-bets-js" */),
  "component---src-pages-games-nfl-model-performance-js": () => import("./../../../src/pages/games/nfl-model-performance.js" /* webpackChunkName: "component---src-pages-games-nfl-model-performance-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nfl-head-coaches-js": () => import("./../../../src/pages/nfl-head-coaches.js" /* webpackChunkName: "component---src-pages-nfl-head-coaches-js" */),
  "component---src-pages-nfl-power-ratings-js": () => import("./../../../src/pages/nfl-power-ratings.js" /* webpackChunkName: "component---src-pages-nfl-power-ratings-js" */),
  "component---src-pages-nfl-power-ratings-nfl-epa-tiers-by-team-js": () => import("./../../../src/pages/nfl-power-ratings/nfl-epa-tiers-by-team.js" /* webpackChunkName: "component---src-pages-nfl-power-ratings-nfl-epa-tiers-by-team-js" */),
  "component---src-pages-nfl-power-ratings-nfl-epa-tiers-js": () => import("./../../../src/pages/nfl-power-ratings/nfl-epa-tiers.js" /* webpackChunkName: "component---src-pages-nfl-power-ratings-nfl-epa-tiers-js" */),
  "component---src-pages-nfl-power-ratings-nfl-metric-comparison-js": () => import("./../../../src/pages/nfl-power-ratings/nfl-metric-comparison.js" /* webpackChunkName: "component---src-pages-nfl-power-ratings-nfl-metric-comparison-js" */),
  "component---src-pages-nfl-power-ratings-nfl-strength-of-schedule-js": () => import("./../../../src/pages/nfl-power-ratings/nfl-strength-of-schedule.js" /* webpackChunkName: "component---src-pages-nfl-power-ratings-nfl-strength-of-schedule-js" */),
  "component---src-pages-nfl-power-ratings-nfl-team-tendencies-js": () => import("./../../../src/pages/nfl-power-ratings/nfl-team-tendencies.js" /* webpackChunkName: "component---src-pages-nfl-power-ratings-nfl-team-tendencies-js" */),
  "component---src-pages-nfl-power-ratings-nfl-win-totals-js": () => import("./../../../src/pages/nfl-power-ratings/nfl-win-totals.js" /* webpackChunkName: "component---src-pages-nfl-power-ratings-nfl-win-totals-js" */),
  "component---src-pages-nfl-receiving-leaders-js": () => import("./../../../src/pages/nfl-receiving-leaders.js" /* webpackChunkName: "component---src-pages-nfl-receiving-leaders-js" */),
  "component---src-pages-qb-rankings-era-adjusted-js": () => import("./../../../src/pages/qb-rankings/era-adjusted.js" /* webpackChunkName: "component---src-pages-qb-rankings-era-adjusted-js" */),
  "component---src-pages-qb-rankings-js": () => import("./../../../src/pages/qb-rankings.js" /* webpackChunkName: "component---src-pages-qb-rankings-js" */),
  "component---src-pages-tools-hedge-calculator-js": () => import("./../../../src/pages/tools/hedge-calculator.js" /* webpackChunkName: "component---src-pages-tools-hedge-calculator-js" */),
  "component---src-pages-tools-nfl-cover-probability-calculator-js": () => import("./../../../src/pages/tools/nfl-cover-probability-calculator.js" /* webpackChunkName: "component---src-pages-tools-nfl-cover-probability-calculator-js" */),
  "component---src-pages-tools-nfl-home-field-advantage-hfa-tracker-js": () => import("./../../../src/pages/tools/nfl-home-field-advantage-hfa-tracker.js" /* webpackChunkName: "component---src-pages-tools-nfl-home-field-advantage-hfa-tracker-js" */),
  "component---src-pages-tools-nfl-odds-calculator-js": () => import("./../../../src/pages/tools/nfl-odds-calculator.js" /* webpackChunkName: "component---src-pages-tools-nfl-odds-calculator-js" */),
  "component---src-pages-tools-parlay-odds-calculator-js": () => import("./../../../src/pages/tools/parlay-odds-calculator.js" /* webpackChunkName: "component---src-pages-tools-parlay-odds-calculator-js" */),
  "component---src-pages-tools-qb-passer-rating-calculator-js": () => import("./../../../src/pages/tools/qb-passer-rating-calculator.js" /* webpackChunkName: "component---src-pages-tools-qb-passer-rating-calculator-js" */),
  "component---src-pages-tools-sportsbook-hold-calculator-js": () => import("./../../../src/pages/tools/sportsbook-hold-calculator.js" /* webpackChunkName: "component---src-pages-tools-sportsbook-hold-calculator-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-game-summary-summaries-js": () => import("./../../../src/templates/game_summary/summaries.js" /* webpackChunkName: "component---src-templates-game-summary-summaries-js" */),
  "component---src-templates-modals-game-modal-js": () => import("./../../../src/templates/modals/game_modal.js" /* webpackChunkName: "component---src-templates-modals-game-modal-js" */),
  "component---src-templates-team-pages-team-page-js": () => import("./../../../src/templates/team_pages/team_page.js" /* webpackChunkName: "component---src-templates-team-pages-team-page-js" */),
  "component---src-templates-weekly-summaries-weekly-summary-js": () => import("./../../../src/templates/weekly_summaries/weekly_summary.js" /* webpackChunkName: "component---src-templates-weekly-summaries-weekly-summary-js" */)
}

